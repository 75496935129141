
        import * as htmlToImage from 'html-to-image';
        import demos from "./script.js";

        window.start_skruller   = demos.start_skruller;
        window.start_gravything = demos.start_gravything;
        window.start_test       = demos.start_test;
        window.stop_demo        = demos.stop_demo;

        window['uibtnhome'].addEventListener( 
            'click',
            (e)=>{
                stop_demo();
            } 
        );
        window['uibtnsound'].addEventListener( 
            'click',
            (e)=>{} 
        );
        window['uibtnclose'].addEventListener( 
            'click',
            (e)=>{} 
        );

        window['playsounds'].addEventListener(
            'click', 
            (e)=>{
                window['playsoundstext'].textContent = (
                    e.target.checked 
                        ? "This website will play sound when you click the buttons" 
                        : "No sound will be played when you click the buttons"
                );
            }
        );
        window['btnabout'].addEventListener(
            'click', 
            (e)=>{
                let aboutstyle=window['aboutme'].style;
                aboutstyle.visibility='visible';
                aboutstyle.opacity='1';    
            }
        );
        window['btnexperience'].addEventListener(
            'click', 
            (e)=>{

            }
        );
        window.close_about = function (){
            window['aboutme'].style.opacity='0';
            const timer = setTimeout(
                ()=>{window['aboutme'].style.visibility='hidden'},
                500
            );
        }

        function view_challenges(){
          console.log('yes')
          htmlToImage.toCanvas(window['overlay'])
          .then(function (canvas) {
            console.log(canvas)
            window['overlay'].textContent = '';
            window['overlay'].appendChild(canvas)
            canvas.style.position = 'absolute';
            const ctx = canvas.getContext("2d");
            
            let left = 0;
            let width  = canvas.getBoundingClientRect().width;
            let height = canvas.getBoundingClientRect().height;
            
            let time = 0;
            const animate = (time)=>{
              // console.log(time)
              for(let x = 0; x<width; x+=width/11){
                for(let y=0; y<height; y+=height/11){
                  ctx.beginPath();
                  ctx.arc(x, 0, 50,0, Math.PI*2);  
                  ctx.fill();
                }
              }

              requestAnimationFrame(animate)
            }
            animate();
          });

        }

        let container = window['containerbtns'];

        const buttons = [
          {text: "View the Skruller",   fn: start_skruller},
          {text: "View the Gravything", fn: start_gravything},
          {text: "View challenges",     fn: view_challenges}
        ]

        buttons.forEach(
          (obj)=>{
            let elem = document.createElement('div');
            elem.className='button';
            elem.innerHTML = obj.text;
            elem.onclick = obj.fn;
            
            container.appendChild(elem);
          }
        )

        let parsed = localStorage.getItem('gits');

        let div= window['githublinks'];
        const setInnerHTML = (parsed) => {
          div.innerHTML = `${parsed || "Loading ..." }`;
        }
        setInnerHTML(parsed);
        // window['links'].appendChild(div)
                
        if(!parsed) {
          fetch('https://api.github.com/users/ZaidaZadkiel/repos')
          .then(
            (res)=>{
              if(res.status!=200) throw "Error fetchin githubs";
              return res.json()
            }
          )
          .then(
            (data)=>{
              if(!Array.isArray(data)) throw "error parsing githubs"

              if(!parsed){
                parsed = (data
                  .sort(
                    (a,b)=>new Date(b.pushed_at) - new Date(a.pushed_at)
                  )
                  .map(
                    r=>`
                    <tr>
                      <td>${
                        r.homepage 
                          ? `<a href="${r.homepage}"><img src="svg/www.png"/></a>`
                          : ''
                      }
                      <td>
                        ${r.description}
                        <a href="${r.html_url}">${r.name}</a><br/>
                      </td>
                      <td>${new Date(r.pushed_at).toLocaleDateString()}</td>
                    </a>`
                  )
                  .join('')
                )
                localStorage.setItem('gits', parsed);
                setInnerHTML(parsed);
              }

              
            }
          )
          .catch((e)=>{
            window['errormessage'].innerHTML =e.message;
          });
        }
    