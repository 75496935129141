import * as THREE from 'three'
window.THREE = THREE;

// import { OrbitControls } from 'three/addons/controls/OrbitControls.js'
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { RGBELoader } from 'three/addons/loaders/RGBELoader.js';

const firstcolor  = 0x22559c;    
const secondcolor = 0xf27370; 
const thirdcolor  = 0xfa9856; 
const fourthcolor = 0xede862; 

const canvas = document.querySelector('canvas.webgl')
let _demo = null;

const sizes = {
    width:  window.innerWidth,
    height: window.innerHeight 
}

console.log("canvas", canvas);
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.toneMapping = THREE.ACESFilmicToneMapping;
renderer.toneMappingExposure = 1;

let camera = new THREE.PerspectiveCamera( 70, window.innerWidth / window.innerHeight, 0.1, 100 );
camera.position.z = 2;
let scene = new THREE.Scene();
const texture = new THREE.TextureLoader().load( 'tex/lavatile.jpg' );
const geometry = new THREE.BoxGeometry();
const material = new THREE.MeshBasicMaterial( { map: texture } );
let mesh = new THREE.Mesh( geometry, material );
scene.add( mesh );


function animate() {
    // requestAnimationFrame( animate );
    camera.position.x += 0.001;
    camera.lookAt(0,0,0);
    renderer.render( scene, camera );
}
// animate();

const resizecam = () => {
    // Update sizes
    sizes.width  = window.innerWidth;
    sizes.height = window.innerHeight;

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    if(_demo) _demo.setSize(sizes.width, sizes.height);
}

window.addEventListener('resize', resizecam);
resizecam();

function start(){
    console.log("startup");

    canvas.style.display="block";
    window['overlay'].style.display='none';
    window['uicontrols'].style.display='block';

    window.addEventListener('dblclick', () => {
        const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement

        if(!fullscreenElement) {
            if(canvas.requestFullscreen) {
                canvas.requestFullscreen()
            } else if (canvas.webkitRequestFullscreen) {
                canvas.webkitRequestFullscreen()
            }
        }
        else {
            if(document.exitFullscreen) {
                document.exitFullscreen()
            }
            else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen()
            }
        }
    });

    if(!_demo) return animate();

    _demo.setSize(sizes)
    _demo.start(renderer);
}

let audioList={};
let listener = null;
function soundLoaded(buffer, name){
    if(!listener) listener = new THREE.AudioListener();;
    const sound    = new THREE.Audio( listener );
    sound.setBuffer( buffer );
    sound.setLoop( true );
    sound.setVolume( 0.5 );
    audioList[name] = sound;
    console.log({audioList})
    if(_demo) _demo.setSoundList(listener, audioList);
}

async function load_demo(path){
    try{
        console.info(path)
        const demo = (await import (`./demos/${path}.js`)).default
        console.log(demo);
        _demo = demo;

        if(!demo.assets)         throw 'demo.assets not defined';
        if(!demo.gltf)           throw 'demo.gltf not defined';
        if(!demo.setSoundList)   throw 'demo.setSoundList not defined';
        if(!demo.setHDRTexture)  throw 'demo.setHDRTexture not defined';
        if(!demo.start)          throw 'demo.start not defined';
        if(!demo.setSize)        throw 'demo.setSize not defined';
        if(!demo.tick)           throw 'demo.tick not defined';

        let assets = demo.assets;
        if(!assets) throw 'Assets not specified, cant load';
        const {hdr, bgm, glb} = assets;
        console.log({hdr});
        console.log({bgm});
        console.log({glb});
        if(!Array.isArray(glb)) throw 'glb is not array, cant continue';

        const manager = new THREE.LoadingManager();
        manager.onStart = function ( url, itemsLoaded, itemsTotal ) {
            console.log( 'Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
        };

        manager.onLoad = function ( ) {
            console.log( 'Loading complete!');
            start();
        };

        manager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
            console.log( 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
        };

        manager.onError = function ( url ) {
            console.log( 'There was an error loading ' + url );
        };

        // const loader = new THREE.OBJLoader( manager );
        // loader.load( 'file.obj', function ( object ) {
        //     //
        // } );

        const audioLoader = new THREE.AudioLoader( manager );
        audioLoader.load( bgm, (buffer)=>soundLoaded(buffer, bgm));

        const loader = new GLTFLoader( manager ).setPath( '/glb/' );
        glb.forEach(path => loader.load(path, demo.gltf) ) 

        new RGBELoader( manager ).setPath( '/tex/' ).load( 
            hdr, 
            demo.setHDRTexture
        );

    }catch(ex){
        console.error(ex)
        window['errormessage'].textContent=ex
    }

}

function stop_sound(){
    //TODO: fade out sounds
    Object.values(audioList).forEach(
        (audio)=>audio.stop() //should unload ?
    )
}

export default {
    stop_demo: ()=>{
        //dispose
        console.log('stop demo');
        canvas.style.display="none";
        window['overlay'].style.display='block';
        window['uicontrols'].style.display='none';
        stop_sound();
    },
    start_gravything: () => {
        return load_demo('gravything');
    },
    start_skruller: () => {
        return load_demo('skruller');
    },
    start_test: ()=>{
        start();
    }
}